import React from "react"

import Index from "../components/index"
import Seo from "../components/other/seo"
import Container from "../components/layout/container";
import PageHeader from "../components/elements/PageHeader";

const RequestDemo = () => (
  <Index>
    <Seo title="Request Demo" />
    <PageHeader>
      <h1>Request Demo</h1>
      <p className="align-center lead">
        Thank you for your interest in receiving an InCircle Review demonstration. <br />
        An InCircle representative will contact you to arrange a date and time.
      </p>
    </PageHeader>
    <Container>
    </Container>
  </Index>
)

export default RequestDemo
